angular.module('BookingApp')

.service "BestDealSortModifier", [

  "$rootScope"
  ($rootScope) ->

    this.perform = (hotels) ->
      return hotels if !hotels

      for hotel in hotels
        if !hotel.trustyou.score.kaligo_overall
           hotel.searchRank += 5000

      return hotels

    return this
]
