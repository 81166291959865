declare var angular : any

export class HainanErrorMapper {

  public error(translation_key) {
    let defaultError = 'Incorrect login credentials'
    let errorsMap = {
      "HAINAN_LOGIN_NEEDS_UPDATE": "hainan.login.errors.update_password",
      "HAINAN_ACCOUNT_SUSPENDED": "hainan.login.errors.suspended",
    }

    return errorsMap[translation_key] || defaultError
  }
}

angular.module('BookingApp').service('HainanErrorMapper', HainanErrorMapper);

