require('../../../booking_app/services/result_modifiers/best-deal-sort-modifier')
require('../../../booking_app/services/result_modifiers/prioritise-translation-modifier')
require('../../../booking_app/services/hainan_error_mapper')
loginModalOptions = require('../../../booking_app/types/login-modal-config')

angular.module('BookingApp')

.config [

  "$routeProvider", "$injector", "KaligoConfig"
  ($routeProvider, $injector, KaligoConfig) ->

    return if KaligoConfig.ignoreAngularRoute

    $routeProvider.when("/terms/hainan-privacy-policy"
      controller: "PageCtrl",
      templateUrl: "/html/terms/privacy_policy2"
    ).when("/terms/privacy"
      redirectTo: "/terms/hainan-privacy-policy"
    ).when("/terms/hainan-terms-conditions"
      controller: "PageCtrl"
      templateUrl: "/html/terms/terms_condition2"
    ).when("/terms/terms_condition"
      redirectTo: "/terms/hainan-terms-conditions"
    )
]

.controller 'HainanCtrl', [

  '$scope', '$rootScope', '$q', '$translate', '$modal', '$location', '$window',
  '$timeout', 'ApiDataService', "PrioritiseTranslationModifier",
  "BestDealSortModifier", "CookieService", "UtilsService", "AppConfig",
  "AppSettings", "HainanErrorMapper", "GlobalStateService"

  ($scope, $rootScope, $q, $translate, $modal, $location, $window,
  $timeout, ApiDataService, PrioritiseTranslationModifier,
  BestDealSortModifier, CookieService, UtilsService, AppConfig,
  AppSettings, HainanErrorMapper, GlobalStateService) ->

    $rootScope.hainan = {}

    $scope.globalStateService = GlobalStateService

    AppSettings.checkoutRedirectToBookingsPath = true
    AppSettings.enableChildrenSearch = true
    AppSettings.loginModalConfig.loginModalWindowClass = loginModalOptions.LoginModalWindowClassOption.HAINAN_CLASS
    AppSettings.contactUsConfig.contactUsEmailText = true
    AppSettings.contactUsConfig.contactUsParagraphText = "wl.hainan.contactus_text.urgent"
    AppSettings.hotelDetailsTemplateConfig.showBgPageColor = true
    AppSettings.hotelDetailsTemplateConfig.showPointCashSliderRoomList = false
    AppSettings.openInTargetTab.checkoutInNewTab = true
    AppSettings.wlTranslationPath = "wl.hainan"
    AppSettings.newLandingPageTiles = false
    $rootScope.globalState.hideUseAddressFromProfile = true
    $rootScope.globalState.hideFooter = true
    $rootScope.globalState.isHainanWL = true
    $rootScope.globalState.requireLoginOnCheckout = true
    $rootScope.globalState.useSimpleLoader = true
    $rootScope.globalState.checkoutHideUserDetailName = true
    $rootScope.globalState.serviceEmail = "hnair-support@kaligo.com"
    $rootScope.globalState.search_results.modifiers = [PrioritiseTranslationModifier, BestDealSortModifier]
    $rootScope.globalState.search_results.ui.extra_disclaimer = "wl.hainan.search_result.pay_with_points.bonus_disclaimer"
    AppSettings.pointsCashSliderSettings.sliderLeftLabel = "txt.cash"
    AppSettings.pointsCashSliderSettings.sliderRightLabel = $rootScope.pointsPartner.shortCurrency
    $rootScope.globalState.displayMembershipAtCheckout = false
    $rootScope.showWelcomeBox = false

    $rootScope.paymentMode = 'credit_card'

    $rootScope.hainan.hotelImage = () ->
      color = if GlobalStateService.isTravelType('hotels')
      then "red" else "gray"
      "#{$rootScope.const.cdnUrl}/assets/images/hainan/whitelabel/hotelmenu_icon_#{color}.png"

    $rootScope.hainan.carImage = () ->
      color = if GlobalStateService.isTravelType('cars')
      then "red" else "gray"
      "#{$rootScope.const.cdnUrl}/assets/images/hainan/whitelabel/carmenu_icon_#{color}.png"

    cashMinimumText = {
      "en": "A minimum 55% points is required when you make the payment with cash."
      "zh": "当您选择部分使用现金时，您至少需要支付55%积分。"
      "hk": "当您选择部分使用现金时，您至少需要支付55%积分。"
    }

    $rootScope.hainan.showTopNav = false
    $rootScope.hainan.displayTextOnLoading = false

    $rootScope.hainan.tncLinks = {
      "en": "http://ffp2015pre.hnair.com/FFPCLUBEN/Front/navigations/rule.aspx"
      "zh": "http://ffp.hnair.com/FFPClub/cn/jpjh/main.html?jp_gztk"
      "hk": "http://ffp.hnair.com/FFPClub/big5/jpjh/main.html?jp_gztk"
    }

    # this is to prevent a any other currencies being selected for Hainan Whitelabel
    allowedCurrency = ['CNY', 'HKD', 'TWD', 'USD']
    $rootScope.allCurrencies = UtilsService.filterArrayAttr(
      $rootScope.allCurrencies,
      allowedCurrency,
      'code'
    )
    $rootScope.enabledLocales = UtilsService.filterArrayAttr(
      $rootScope.enabledLocales,
      ['zh', 'hk', 'en'],
      'code'
    )

    defaultCurrency = $rootScope.currenciesService.findByCode('CNY')
    if $rootScope.selectedCurrency.code not in allowedCurrency
      # Need to use angular.copy here!
      $rootScope.selectedCurrency = angular.copy(defaultCurrency)
      CookieService.setCurrency($rootScope.selectedCurrency.code)

    $rootScope.convert_rate = $rootScope.selectedCurrency.rate

    $rootScope.hainan.toggleTopNav = () ->
      $rootScope.hainan.showTopNav = !$rootScope.hainan.showTopNav

    $rootScope.$on "$routeChangeSuccess", (e, current, previous) ->
      $rootScope.globalState.alertMsg = "" if previous

      # catch 404 angular route path errors
      return if !current || !current.$$route

      currentController = current.$$route.controller
      $rootScope.hainan.displayTextOnLoading = (currentController == "ResultsCtrl")

    updateHainanRegisterUrl = () ->
      if $rootScope.selectedLocale && $rootScope.selectedLocale.lang_code == "en"
        $rootScope.hainan.registerUrl = "https://www.hainanairlines.com/HUPortal/dyn/portal/DisplayPage?COUNTRY_SITE=US&SITE=CBHZCBHZ&LANGUAGE=US&PAGE=HTJO"
      else if $rootScope.traditionalChineseUser()
        $rootScope.hainan.registerUrl = "http://ffp.hnair.com/FFPClub/member/registerConfirm?lang=big5"
      else
        $rootScope.hainan.registerUrl = "http://ffp.hnair.com/FFPClub/member/registerConfirm"
      return

    updateHainanForgetPasswordUrl = () ->
      if $rootScope.selectedLocale && $rootScope.selectedLocale.lang_code == "en"
        $rootScope.hainan.forgetPasswordUrl = "http://ffp2015pre.hnair.com/FFPCluben/GetPassword.aspx?"
      else if $rootScope.traditionalChineseUser()
        $rootScope.hainan.forgetPasswordUrl = "http://ffp.hnair.com/FFPClub/member/getPassword?lang=big5"
      else
        $rootScope.hainan.forgetPasswordUrl = "http://ffp.hnair.com/FFPClub/member/getPassword"
      return

    triggerCloseDropdown = ->
      $(document).trigger($.Event('click.bs.dropdown.data-api'))

    $rootScope.$watch 'selectedLocale', (newvalue, oldvalue) ->
      return if !$rootScope.selectedLocale
      updateHainanRegisterUrl()
      updateHainanForgetPasswordUrl()
      triggerCloseDropdown()
      $rootScope.globalState.search_results.cashMinimumTooltip =
        cashMinimumText[$rootScope.selectedLocale.code]

    $rootScope.$watch 'selectedCurrency', (newvalue, oldvalue) ->
      triggerCloseDropdown()

    $rootScope.hainan.loginHainan = () ->
      return if $rootScope.globalState.processing && $rootScope.hainan.signInForm.$invalid
      $rootScope.globalState.processing = true
      $rootScope.isLoading = true
      $rootScope.hainan.loginFormErrors = null
      params = {
        user: {email: $rootScope.hainan.userEmail, password: $rootScope.hainan.userPassword }
      }
      ApiDataService.post('hainan/sign_in', params).then(
        (res) ->
          $rootScope.isLoading = false
          displayOtpModal(res.otp_information)
        , (res) ->
          $rootScope.isLoading = false
          $rootScope.globalState.processing = false
          $rootScope.hainan.loginFormErrors = HainanErrorMapper.error(res['errors'])
      )

    displayOtpModal = (otpInformation) ->
      $rootScope.globalState.activeModal = true

      modalInstance = $modal.open({
        animation: true
        templateUrl: '/html/whitelabel/hainan/login_notification'
        controller: 'LoginNotificationModal'
        size: "lg"
        windowClass: 'hainan-system-maintenance-modal'
        keyboard: false
        resolve: {
          otpChannel: () ->
            return otpInformation['channel']
          otpRecipient: () ->
            return otpInformation['recipient']
          modalYes: () ->
            return $translate.instant("Ok")
        }
      })

      modalInstance.result.then(
        (selection) ->
          $window.location.reload()
        , () ->
          $window.location.reload()
      )

    return

]
