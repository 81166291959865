MemberInfoCtrl = ($scope) ->

  $scope.pointsToPay = () ->
    return $scope.car.points_payment if $scope.car
    return $scope.hotelPrice.points_payment if $scope.hotelPrice

MemberInfoCtrl
  .$inject = [
    '$scope',
  ]

angular.module('BookingApp')
  .controller 'MemberInfoCtrl', MemberInfoCtrl
