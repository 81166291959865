require('../cars-setup');

global.wlAngularDeps = [
  'ngAnimate',
];

global.loadAppDeps = function() {
  loadCarsAppDeps();
  require('angular-animate');
  require('../../assets/javascripts/whitelabel/hainan/locales');
  require('../../assets/javascripts/whitelabel/hainan/controllers/hainan-controller');
  require('../../assets/javascripts/whitelabel/hainan/controllers/member-info-controller');
  require('../../assets/javascripts/whitelabel/hainan/controllers/otp-controller');
}

require('../application');
