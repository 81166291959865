import { ApiDataService } from 'booking_app/services/api-data.service';

export class OTPCtrl {

  static $inject = [
    '$interval',
    'ApiDataService',
  ];

  static OTP_COUNTER_UPDATE_INTERVAL = 1000; //1000 ms
  static OTP_GENERATION_INTERVAL_SECONDS = 120;

  // injectors
  buttonEnabled: boolean;
  countdownText: string;

  constructor(
    private $interval,
    private apiDataService: ApiDataService,
  ) {
    this.enableButton();
  }

  generateOTP(): void {
    this.buttonEnabled = false;
    this.apiDataService.post('user/generate_otp').finally(
      () => { this.triggerButtonCounter(); }
    )
  }

  private triggerButtonCounter(): void {
    this.$interval(
      (intervalCount) => { this.countdownText = this.activeCountdownText(intervalCount); },
      OTPCtrl.OTP_COUNTER_UPDATE_INTERVAL,
      OTPCtrl.OTP_GENERATION_INTERVAL_SECONDS,
      true
    ).then(() => this.enableButton())
  }

  private enableButton() {
    this.buttonEnabled = true;
    this.countdownText = null;
  }

  private activeCountdownText(currentValue) {
    return `(${OTPCtrl.OTP_GENERATION_INTERVAL_SECONDS - currentValue})`;
  }
}

angular.module("BookingApp").controller("OTPCtrl", OTPCtrl);
